import axios from 'axios';
import configs from '../configs/config';

export const ApiCreateRControl = async (newData) => {
    return await axios.post(`${configs.URL_API_BASE}/rcontrol`, newData);
}

export const ApiPutRControl = (id, data) => {
    return axios.put(`${configs.URL_API_BASE}/rcontrol/${id}`, data);
}

export const getDataRControl = () => {
    return axios.get(`${configs.URL_API_BASE}/rcontrol`);
}

export const getDataByIdRControl = async (id) => {
    return await axios.get(`${configs.URL_API_BASE}/rcontrol/${id}`);
}