import React, { Component } from 'react';
import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExcelExport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSet: this.props.data.map((item) => {
                return {
                    ID: item.ID,
                    id: item.id,
                    reservation_date: item.reservation_date,
                    reservation_num: item.reservation_num,
                    cost: item.cost,
                    rci_profit: item.rci_profit,
                    trans_profit: item.trans_profit,
                    auto_payment: item.auto_payment,
                }
            }),

        };
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            dataSet: nextProps.data.map((item) => {
                return {
                    ID: item.ID,
                    id: item.id,
                    reservation_date: item.reservation_date,
                    reservation_num: item.reservation_num,
                    cost: item.cost,
                    rci_profit: item.rci_profit,
                    trans_profit: item.trans_profit,
                    auto_payment: item.auto_payment,
                }
            }),
        });
    }

    render() {
        return (
            <div className='excel-download-btn'>
                <ExcelFile
                    element={
                        <button type='button' className="button btn-green">
                            <FontAwesomeIcon icon="download" /> Export Excel
                        </button>}>
                    <ExcelSheet data={this.state.dataSet} name="Products">
                        <ExcelColumn label="RESERVATION DATE" value="reservation_date" />
                        <ExcelColumn label="RESERVATION NUMBER" value="reservation_num" />
                        <ExcelColumn label="TOTAL COST" value="cost" />
                        <ExcelColumn label="FEES RCI" value="rci_profit" />
                        <ExcelColumn label="FEES TRANSROUTE" value="trans_profit" />
                        <ExcelColumn label="AUTOPAYMENT" value="auto_payment" />
                    </ExcelSheet>
                </ExcelFile>
            </div>
        );
    }
}

export default ExcelExport;