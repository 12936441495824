import moment from 'moment';

// *********
// Helpers 
// *********

const getCodeAux = () => {
    return `TR${moment().year('YY').format('YY')}${moment().month('M').format('MM')}${moment().day('D').format('DD')}-${Math.floor(Math.random() * 99999)}`;
};

const parseRate = (rate) => {
    // actualmente la tarifa con 20% de descuento
    return rate - (rate * 0.1);
};

// *********
// Parsers
// *********

export const parserEmail = (data, id) => {

    let email = {
        "email_to": data.contact_email,
        "option": "confirm-tr",
        "data": {
            "confirmation_service": id,
            "authorization_code": data.payment_auth_code,
            "search_params": {
                "pickup_location": data.pickup,
                "destination": data.destination,
                "n_passengers": data.passengers,
                "trip_type": (data.trip_type === 'Round Trip') ? 'Round Trip' : 'One Way',
                "price": parseRate(data.rate),
                "transportation": data.transport,
                "comments": data.observations
            },
            "arrival": {
                "date_time": `${moment(data.arrival_datetime.split('T')[0] + 'T'+ data.arrival_time).format('MMM DD YYYY h:mm A')}`,
                "airline": data.arrival_airline,
                "fligth_number": data.arrival_flight
            },
            "customer": {
                "fullname": data.contact_name,
                "email": data.contact_email,
                "phone": data.contact_phone
            },
            "agent": {
                "id": data.agent_id,
                "id_partner": data.member_id
            }
        }
    }

    if (data.trip_type === 'Round Trip') {
        email.data.departure = {
            "date_time": `${moment(data.departure_datetime.split('T')[0] + 'T' + data.departure_time).format('MMM DD YYYY h:mm A')}`,
            "airline": data.departure_airline,
            "fligth_number": data.departure_flight,
            "hotel_pickup": data.hotel_extra || data.destination
        }
    }

    return email;
}

export const parserEmailEdit = (data) => {
    if (data.trip_type === 'One Way') {
        return {
            "email_to": data.selected_res.CORREO,
            "option": "confirm-tr",
            "data": {
                "confirmation_service": data.selected_res.ID,
                "authorization_code": data.selected_res.PAGO,
                "search_params": {
                    "pickup_location": data.arrival_hotel,
                    "destination": data.hotel_destination,
                    "n_passengers": data.passengers,
                    "trip_type": (data.hasdeparture) ? 'Round Trip' : 'One Way',
                    "price": data.selected_res.COSTO,
                    "transportation": data.transport,
                    "comments": data.observations
                },
                "arrival": {
                    "date_time": data.arrival_date + " " + data.arrival_time,
                    "airline": data.arrival_airline,
                    "fligth_number": data.arrival_flight
                },
                "customer": {
                    "fullname": data.client,
                    "email": data.selected_res.CORREO,
                    "phone": data.selected_res.TELEFONO
                },
                "agent": {
                    "id": data.agent_id,
                    "id_partner": data.partner_id
                }
            }
        }
    } else {
        return {
            "email_to": data.selected_res.CORREO,
            "option": "confirm-tr",
            "data": {
                "confirmation_service": data.selected_res.ID,
                "authorization_code": data.selected_res.PAGO,
                "search_params": {
                    "pickup_location": data.arrival_hotel,
                    "destination": data.hotel_destination,
                    "n_passengers": data.passengers,
                    "trip_type": (data.hasdeparture) ? 'Round Trip' : 'One Way',
                    "price": data.selected_res.COSTO,
                    "transportation": data.transport,
                    "comments": data.observations
                },
                "arrival": {
                    "date_time": data.arrival_date + " " + data.arrival_time,
                    "airline": data.arrival_airline,
                    "fligth_number": data.arrival_flight
                },
                "departure": {
                    "date_time": data.departure_date + " " + data.departure_time,
                    "airline": data.departure_airline,
                    "fligth_number": data.departure_flight,
                    "hotel_pickup": data.departure_hotel
                },
                "customer": {
                    "fullname": data.client,
                    "email": data.selected_res.CORREO,
                    "phone": data.selected_res.TELEFONO
                },
                "agent": {
                    "id": data.agent_id,
                    "id_partner": data.partner_id
                }
            }
        }
    }
}

export const parserResendEmail = (_data) => {
    let email = {
        "email_to": _data.CORREO,
        "option": "confirm-tr",
        data: {
            "confirmation_service": _data.ID,
            "authorization_code": _data.PAGO,
            "search_params": {
                "pickup_location": _data.HOTEL,
                "destination": _data.HOTEL_DESTINO,
                "n_passengers": _data.NPASAJEROS,
                "trip_type": (_data.FECHA_SALIDA != 'N/A') ? 'Round Trip' : 'One Way',
                "price": _data.COSTO,
                "transportation": _data.TRANSPORTE,
                "comments": _data.COMENTARIOS
            },
            "arrival": {
                "date_time": _data.FECHA_LLEGADA + " " + _data.HORA_IN,
                "airline": _data.AEROLINEA_IN,
                "fligth_number": _data.VUELO_IN
            },
            "customer": {
                "fullname": _data.NOMBRE,
                "email": _data.CORREO,
                "phone": _data.TELEFONO
            },
            "agent": {
                "id": _data.AGENTE_ID,
                "id_partner": _data.AGENTE_NOMBRE
            }
        }
    };

    if (_data.FECHA_SALIDA != 'N/A') {
        email.data.departure = {
            "date_time": _data.FECHA_SALIDA + " " + _data.HORA_OUT,
            "airline": _data.AEROLINEA_OUT,
            "fligth_number": _data.VUELO_OUT,
            "hotel_pickup": _data.HOTEL_EXTRA || _data.HOTEL_DESTINO
        }
    }

    return email;
}

export const parserInsert = (data) => {
    return {
        "ID": getCodeAux(),
        "NOMBRE": data.contact_name,
        "APELLIDO": ".",
        "CORREO": data.contact_email,
        "TELEFONO": data.contact_phone,
        "CIUDAD": "'N/A'",
        "ESTADO": "'N/A'",
        "PAIS": "'N/A'",
        "FECHA_REGISTRO": moment().format('DD/MM/YYYY HH:mm'),
        "FECHA_LLEGADA": moment(data.arrival_datetime).format('DD/MM/YYYY'),
        "HORA_IN": data.arrival_time,
        "VUELO_IN": data.arrival_flight,
        "AEROLINEA_IN": data.arrival_airline,
        "FECHA_SALIDA": (data.trip_type === 'Round Trip') ? moment(data.departure_datetime).format('DD/MM/YYYY') : 'N/A',
        "HORA_OUT": (data.trip_type === 'Round Trip') ? data.departure_time : 'N/A',
        "VUELO_OUT": (data.trip_type === 'Round Trip') ? data.departure_flight : 'N/A',
        "AEROLINEA_OUT": (data.trip_type === 'Round Trip') ? data.departure_airline : 'N/A',
        "HOTEL": data.pickup,
        "ZONA": data.destination_zone,
        "TRANSPORTE": data.transport,
        "NPASAJEROS": data.passengers,
        "COSTO": parseRate(data.rate),
        "MONEDA": "USD",
        "COMENTARIOS": data.observations,
        "CONFIRMAR": "SI",
        "AGENTE_ID": data.agent_id,
        "AGENTE_NOMBRE": data.member_id,
        "HOTEL_DESTINO": data.destination,
        "HOTEL_EXTRA": (data.trip_type === 'Round Trip' || data.hotel_extra === '' || data.hotel_extra === undefined) ? data.hotel_extra : data.destination,
        "OBSERVACIONES": data.observations,
        "PAGO": data.payment_auth_code
    };
}

