import React, { Component } from 'react';
import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExcelExport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSet: []
        };
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            dataSet: this.parseFileContent(nextProps.data)
        });
    }

    parseFileContent = (reservations) => {

        if(reservations.length > 0){
            let columnNames = Object.keys(reservations[0]);
            columnNames.shift();
            columnNames.shift();
    
            let columns = [];
            columnNames.forEach((item) => {
                columns.push(item.split('_').join(' '));
            });
    
            let data = [];
            reservations.forEach((_item) => {
                let sub_data = [];
    
                columnNames.forEach((key) => {
                    sub_data.push({
                        value: String(_item[key]),
                    });
                });
    
                data.push(sub_data);
            })
    
            return [{
                columns,
                data
            }];
        }else{
            let columns= [], data = [];
            return [{
                columns,
                data
            }];
        }

    }

    render() {
        const { dataSet } = this.state;
        return (

            <div className='excel-download-btn'>
                <ExcelFile
                    element={
                        <button type='button' className="button btn-green">
                            <FontAwesomeIcon icon="download" /> Export Excel
                        </button>}>
                    <ExcelSheet dataSet={dataSet} name="Reservations" />
                </ExcelFile>
            </div>
        );
    }
}

export default ExcelExport;