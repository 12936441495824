import React, { Component, Fragment } from 'react';
import moment from 'moment';

export default class modalContent extends Component {
    
    render() {

        const { destination, trip_type } = this.props.reserve;

        return (
            <Fragment>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        {/*     MODAL HEADER         */}
                        <div className="modal-header">
                            <div>
                                <h3>Please confirm your reservation</h3>
                                <h4>Promotion: <span className="promotion_text">10%</span> off</h4>
                            </div>
                            <div className="rates text-right">
                                <h5>Rate: <span className="line-through">${this.props.reserve.rate}  USD</span></h5>
                                <h4>Rate: ${this.props.reserve.rate - (this.props.reserve.rate * 0.1)} USD</h4>
                            </div>
                            {/* <button autoFocus type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.closeModal}><span aria-hidden="true">&times;</span></button> */}
                        </div>
                        {/*     MODAL BODY    */}
                        <div className="modal-body">
                            <div className="grid-container">
                                <div className="grid-x grid-padding-x grid-padding-y">
                                    <div className="small-12 medium-6 cell callout">
                                        <h5>Customer information:</h5>
                                        <div id="fullnameTraveler">
                                            <p><b>Full name: </b> <span className="fullname">{this.props.reserve.contact_name}</span></p>
                                        </div>
                                        <div id="phoneTraveler">
                                            <p><b>Phone: </b><span className="phone">{this.props.reserve.contact_phone}</span></p>
                                        </div>
                                        <div id="emailTraveler">
                                            <p><b>E-mail: </b><span className="email">{this.props.reserve.contact_email}</span></p>
                                        </div>
                                        <h5>Transport Selected:</h5>
                                        <p><b>Vehicle:</b> <span className="">{this.props.reserve.transport}</span></p>
                                        <p><b>Trip Type:</b> <span className="">{this.props.reserve.trip_type}</span></p>
                                        <p><b>Passengers Number:</b> <span className="pax_num">{this.props.reserve.passengers}</span></p>
                                        <p><b>Pickup Location:</b> <span className="transport">{this.props.reserve.pickup}</span></p>
                                        <p><b>Destination:</b> <span className="transport">{this.props.reserve.destination}</span></p>
                                    </div>
                                    <div className="small-12 medium-6 cell callout">
                                        <h5>
                                            {destination === "AIRPORT SJD" && trip_type === "One Way"
                                            ? "Departure Information:"
                                            : "Arrival Information:"}{" "}
                                        </h5>
                                        <p><b>Date / Time:</b> <br /> <span className=" arrival_date">{moment(this.props.reserve.arrival_datetime).format('DD/MM/YYYY')} / {this.props.reserve.arrival_time}</span></p>
                                        <p className="flightNumberArrival"><b>Flight Number: </b><br /><span className="flight-numberArrival">{this.props.reserve.arrival_flight}</span></p>
                                        <p className="airlineNameArrival"><b>Airline Name: </b><br /><span className="airline-nameArrival">{this.props.reserve.arrival_airline}</span></p>
                                        <hr />
                                        {
                                            (this.props.reserve.trip_type === 'Round Trip') ?
                                                <Fragment>
                                                    <h5>Departure Information:</h5>
                                                    <p><b>Date / Time:</b><br /><span className="departure_date">{moment(this.props.reserve.departure_datetime).format('DD/MM/YYYY')} / {this.props.reserve.departure_time}</span></p>
                                                    <p className="flightNumberDeparture"><b>Hotel Departure: </b><br /><span className="flight-numberDeparture">{this.props.reserve.hotel_extra}</span></p>
                                                    <p className="flightNumberDeparture"><b>Flight Number: </b><br /><span className="flight-numberDeparture">{this.props.reserve.departure_flight}</span></p>
                                                    <p className="airlineNameDeparture"><b>Airline Name: </b><br /><span className="airline-nameDeparture">{this.props.reserve.departure_airline}</span></p>
                                                </Fragment> : null
                                        }

                                    </div>
                                    <div className="small-12 cell callout">
                                        <h5>RESERVATION / CANCELATION POLICY:</h5>
                                        <div className="text-justify politicas">
                                            <small>
                                                <ul>
                                                    <li>All Reservations are non-transferable</li>
                                                    <li>In order to ensure availability of vehicles we ask that all reservations must be made at least 48hrs prior to transportation service date, being arrival or departure.</li>
                                                    <li>For arrival modifications a  48 hour notification must be made prior to schedule.</li>
                                                    <li>For departure modifications  a 24 hour notification must be made prior to schedule.</li>
                                                    <li>Any cancelation including any major causes will be subject to a 10% retention fee for administrative costs.</li>
                                                    <li>All cancelations must be made at least 48hrs prior to service date, being arrival or departure</li>
                                                    <li>All cancelations made within the 48hrs time frame will be subject to 50% retention of the total cost.</li>
                                                    <li>Any no show on either arrival/ departure or both dates will also be subject to 50% retention of the total cost. Any claims of the refundable amount must be made within 30days post service day.</li>
                                                    <li>Partial or total refunds will be applied according on times, payment method and penalty percentage defined by the payment system chosen.
                                                    <br />*Claims made after the 30day period or any cancelation that does not meet cancelation criteria mentioned above will be completely non-refundable.</li>
                                                </ul>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*     MODAL FOOTER         */}
                        <div className="modal-footer d-flex justify-content-end">
                            <div className="grid-x">
                                <button type="button" className="button btn-primary noprint" onClick={this.props.closeModal}><i className="fa fa-times-circle" aria-hidden="true"></i>  &nbsp; CANCEL</button>
                                <button type="button" className="button btn-secondary noprint" onClick={this.props.saveReservation}> <i className="fa fa-check-circle"></i>  &nbsp; CONFIRM - SEND EMAIL VOUCHER</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
