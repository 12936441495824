import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

class ReservationSummary extends Component {
  render() {
    const _transport =
      this.props.reserve.transport == "Sedan"
        ? "Sedan / Sienna"
        : this.props.reserve.transport;
    const { destination, trip_type } = this.props.reserve;

    return (
      <div className="medium-4 cell">
        <div className="callout">
          <h4>Your Reservation Summary</h4>
          <div className="grid-x callout warning">
            <div className="small-12 cell text-right">
              <h5>
                Promotion <span className="font-bold promotion_text">10%</span>{" "}
                Off
              </h5>
              <h6>
                Regular Rate:{" "}
                <span className="line-through">
                  $ {this.props.reserve.rate} USD
                </span>
              </h6>
            </div>
            <div className="small-6 cell">
              <h4>TOTAL:</h4>
            </div>
            <div className="small-6 cell text-right">
              <h4 className="font-bold">
                {" "}
                ${this.props.reserve.rate - this.props.reserve.rate * 0.1} USD
              </h4>
            </div>
            {this.props.reserve.rate == 0 ? (
              <div className="small-12 cell">
                <div className="grid-x alert alert-danger text-left">
                  <div className="small-4 cell icon-alert">
                    <FontAwesomeIcon icon="exclamation-triangle" />
                  </div>
                  <div className="small-8">
                    NOT FOUND RATES, <br /> SELECT CORRECT ZONES
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="transport-selected">
            <h5>Transport Selected:</h5>
            <div id="img">
              <img className="" src={this.props.reserve.img_transport} alt="" />
            </div>
          </div>
          <hr />
          <p>
            <b>Trip Type:</b>{" "}
            <span className="">{this.props.reserve.trip_type}</span>
          </p>
          <p>
            <b>Passengers Number:</b>{" "}
            <span className="pax_num">{this.props.reserve.passengers}</span>
          </p>
          <p>
            <b>Transport Selected:</b>{" "}
            <span className="transport">{_transport}</span>
          </p>
          <p>
            <b>Pickup Location:</b>{" "}
            <span className="transport">{this.props.reserve.pickup}</span>
          </p>
          <p>
            <b>Destination:</b>{" "}
            <span className="transport">{this.props.reserve.destination}</span>
          </p>
          <hr />
          {/* el arrivo o destino es el aeropuerto: */}
          <h5>
            {destination === "AIRPORT SJD" && trip_type === "One Way"
              ? "Departure Information:"
              : "Arrival Information:"}{" "}
          </h5>
          <div className="grid-x">
            <div className="small-12 cell">
              <p>
                <b>Date / Time:</b> <br />{" "}
                <span className=" arrival_date">
                  {
                    moment(this.props.arrival_datetime).format('DD/MM/YYYY')
                  }
                </span>
              </p>
            </div>
            <div className="small-6 cell">
              <p className="flightNumberArrival">
                <b>Flight Number: </b>
                <br />
                <span className="flight-numberArrival">
                  {this.props.reserve.arrival_flight}
                </span>
              </p>
            </div>
            <div className="small-6 cell">
              <p className="airlineNameArrival">
                <b>Airline Name: </b>
                <br />
                <span className="airline-nameArrival">
                  {this.props.reserve.arrival_airline}
                </span>
              </p>
            </div>
          </div>

          {this.props.reserve.trip_type == "Round Trip" ? (
            <Fragment>
              <hr />
              <h5>Departure Information:</h5>
              <div className="grid-x">
                <div className="small-12 cell">
                  <p>
                    <b>Date / Time:</b>
                    <br />
                    <span className="departure_date">
                      {
                        moment(this.props.departure_datetime).format('DD/MM/YYYY')
                      }
                    </span>
                  </p>
                </div>
                <div className="small-12 cell">
                  <p>
                    <b>Hotel Departure:</b>
                    <br />
                    <span className="departure_date">
                      {this.props.reserve.hotel_extra}
                    </span>
                  </p>
                </div>
                <div className="small-6 cell">
                  <p className="flightNumberDeparture">
                    <b>Flight Number: </b>
                    <br />
                    <span className="flight-numberDeparture">
                      {this.props.reserve.departure_flight}
                    </span>
                  </p>
                </div>
                <div className="small-6 cell">
                  <p className="airlineNameDeparture">
                    <b>Airline Name: </b>
                    <br />
                    <span className="airline-nameDeparture">
                      {this.props.reserve.departure_airline}
                    </span>
                  </p>
                </div>
              </div>
            </Fragment>
          ) : null}
          <hr />
          <h5>Contact / Traveler Information:</h5>
          <div id="fullnameTraveler">
            <p>
              <b>Full name: </b>{" "}
              <span className="fullname">
                {this.props.reserve.contact_name}
              </span>
            </p>
          </div>
          <div id="phoneTraveler">
            <p>
              <b>Phone: </b>
              <span className="phone">{this.props.reserve.contact_phone}</span>
            </p>
          </div>
          <div id="emailTraveler">
            <p>
              <b>E-mail: </b>
              <span className="email">{this.props.reserve.contact_email}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ReservationSummary;
