import React, { Component, Fragment } from 'react';
import Header from './../utils/header';
import Footer from './../utils/footer';

import { getData, getFilterData, getDataById, ApiPutReservation } from './../../services/reservation';

import { getDataByIdRControl } from '../../services/rates_control';

import { validateToken, validateUserLvl, getUserLvl } from '../../session/session_controller';
import TableReservations from './tablereservations';
import DatePicker from 'react-datepicker';
import moment, { relativeTimeThreshold } from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay';
import Places from './../../places.json';
import ExcelExport from './excelexport';
import Select from 'react-select';
import { sendEmail } from './../../api_controller/email_controller';
import { parserEmailEdit, parserResendEmail } from '../utils/parser';

const nowDate = new Date();

//console.log('Places.airlines', Places.airlines)

const loaderStyles = {
    overlay: (base) => ({
        ...base,
        background: 'rgba(64, 64, 66, 0.9)'
    })
}

export default class index extends Component {

    constructor() {
        super();

        this.state = {
            modalActionEdit: false,
            reservations: [],
            download_reservations: [],
            date_init: '',
            date_end: '',
            modalIsOpen: false,
            selected_res: {
            },
            isloading: false,
            userlvl: getUserLvl(),
            units: Places.units,
            airlines: Places.airlines,
            //variables de formulario de edicion
            partner_id: '',
            reservation: '',
            agent_id: '',
            client: '',
            transport: '',
            passengers: '',
            capacity: [],
            phone: '',
            // Aux 
            arrival_date_selected: '',
            departure_date_selected: '',
            aux_arrival_airline: '',
            aux_departure_airline: '',
            //-------------------
            place1: {},
            arrival_hotel: '',
            arrival_date: '',
            arrival_time: '',
            arrival_flight: '',
            arrival_airline: '',
            //-------------------
            hasdeparture: false,
            //-------------------
            place2: {},
            departure_hotel: '',
            departure_date: '',
            departure_time: '',
            departure_flight: '',
            departure_airline: '',
            hotel_extra: '',
            //-------------------
            observations: '',
            extra_charge: '',
            object: {},
            id_edit: '',
            //-------------------- register, arrival, departure
            filterOption:'register'
        }

        validateToken();
        validateUserLvl();

        getData('register').then((res) => {

            let reserves = [];
            res.data.results.forEach((item) => {
                let r = {

                    id: item.ID_RESERVATION,
                    ID: item.ID,
                    REGISTRATION_DATE: item.FECHA_REGISTRO,
                    AGENTE_ID: item.AGENTE_ID,
                    PARTHER_ID: item.AGENTE_NOMBRE,
                    ID_RESERVATION: item.ID,
                    NAME: item.NOMBRE + ' ' + item.APELLIDO,
                    EMAIL: item.CORREO,
                    PHONE: item.TELEFONO,
                    PICKUP_LOCATION: item.HOTEL,
                    DESTINATION: item.HOTEL_DESTINO,
                    ARRIVAL_DATE: item.FECHA_LLEGADA,
                    ARRIVAL_AIRLINE: item.AEROLINEA_IN,
                    ARRIVAL_FLIGHT: item.VUELO_IN,
                    ARRIVAL_TIME: item.HORA_IN,
                    DEPARTURE_DATE: item.FECHA_SALIDA,
                    DEPARTURE_AIRLINE: item.AEROLINEA_OUT,
                    DEPARTURE_FLIGHT: item.VUELO_OUT,
                    DEPARTURE_TIME: item.HORA_OUT,
                    DEPARTURE_HOTEL: item.HOTEL_EXTRA,
                    ZONE: (item.ZONA == 'Zone 0') ? 'Airport SJD' : item.ZONA,
                    TRANSPORT: item.TRANSPORTE,
                    PASSENGERS: item.NPASAJEROS,
                    COST: item.COSTO,
                    PAID_OUT: item.CONFIRMAR,
                    COMMENTS: item.COMENTARIOS,
                    AUTH_CODE: item.PAGO

                };
                reserves.push(r);
            });
            this.setState({ reservations: reserves, download_reservations: reserves });
        });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    //SUBMIT DEL FILTRADO DE FECHAS
    handleSubmit = (e) => {
        e.preventDefault();
        //Validar que no vengan vacias 
        let validate = true;
        if (this.state.date_init === '') {
            validate = false;
        }
        if (this.state.date_end === '') {
            validate = false;
        }

        if (validate) {
            this.setState({ isloading: true });
            getFilterData(moment(this.state.date_init).format("DD/MM/YYYY"), moment(this.state.date_end).format("DD/MM/YYYY"), this.state.filterOption).then((res) => {

                let reserves = [];
                res.data.results.forEach((item) => {

                    let r = {
                        id: item.ID_RESERVATION,
                        ID: item.ID,
                        REGISTRATION_DATE: item.FECHA_REGISTRO,
                        AGENTE_ID: item.AGENTE_ID,
                        PARTHER_ID: item.AGENTE_NOMBRE,
                        ID_RESERVATION: item.ID,
                        NAME: item.NOMBRE + ' ' + item.APELLIDO,
                        EMAIL: item.CORREO,
                        PHONE: item.TELEFONO,
                        PICKUP_LOCATION: item.HOTEL,
                        DESTINATION: item.HOTEL_DESTINO,
                        ARRIVAL_DATE: item.FECHA_LLEGADA,
                        ARRIVAL_AIRLINE: item.AEROLINEA_IN,
                        ARRIVAL_FLIGHT: item.VUELO_IN,
                        ARRIVAL_TIME: item.HORA_IN,
                        DEPARTURE_DATE: item.FECHA_SALIDA,
                        DEPARTURE_AIRLINE: item.AEROLINEA_OUT,
                        DEPARTURE_FLIGHT: item.VUELO_OUT,
                        DEPARTURE_TIME: item.HORA_OUT,
                        DEPARTURE_HOTEL: item.HOTEL_EXTRA,
                        ZONE: (item.ZONA == 'Zone 0') ? 'Airport SJD' : item.ZONA,
                        TRANSPORT: item.TRANSPORTE,
                        PASSENGERS: item.NPASAJEROS,
                        COST: item.COSTO,
                        PAID_OUT: item.CONFIRMAR,
                        COMMENTS: item.COMENTARIOS,
                        AUTH_CODE: item.PAGO
                    };

                    reserves.push(r);

                });

                this.setState({ reservations: reserves }, () => {
                    this.setState({ isloading: false });
                });

            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Incorrect dates.',
                text: 'Please, select correct dates...',
            })
        }
    };

    handleDateinChange = date => {
        this.setState({ date_init: date });
    };

    handleDateEndChange = date => {
        this.setState({ date_end: date });
    };

    handleDateinEditChange = date => {
        //console.log('date.>', moment(date).format("DD/MM/YYYY"))
        this.setState({ arrival_date: moment(date).format("DD/MM/YYYY"), arrival_date_selected: moment(moment(date, "DD/MM/YYYY")).toDate() });
    }

    handleDateOutEditChange = date => {
        //console.log('date2>', moment(date).format("DD/MM/YYYY"))
        this.setState({ departure_date: moment(date).format("DD/MM/YYYY"), departure_date_selected: moment(moment(date, "DD/MM/YYYY")).toDate() });
    }

    handleDetails = (record) => {
        this.setState({ isloading: true })
        getDataById(record.ID).then(async (res) => {

            let o = res.data.results[0];
            let RControlData = await getDataByIdRControl(o.ID_RESERVATION);

            if (RControlData.data.results.length > 0) {
                o.rate_control = true;
                o.rcontrol = RControlData.data.results[0]
            } else {
                o.rate_control = false;
            }

            if (o.TRANSPORTE === 'Sedan' || o.TRANSPORTE === 'Sedan/Sienna') {
                o.image = Places.units[0].image;
            } else if (o.TRANSPORTE === 'Hiace' || o.TRANSPORTE === 'Hiace/Transit') {
                o.image = Places.units[2].image;
            } else if (o.TRANSPORTE === 'Suburban' || o.TRANSPORTE === 'Suburban/Sienna') {
                o.image = Places.units[1].image;
            } else if (o.TRANSPORTE === 'Limo') {
                o.image = Places.units[3].image;
            }

            this.setState({
                selected_res: o,
            }, () => {
                this.setState({ isloading: false }, () => {
                    this.setState({ modalIsOpen: true });
                })
            });
        });
    };

    openModalEdit = () => {
        this.setState({ modalActionEdit: true });
    };

    closeModalEdit = () => {
        this.setState({ modalActionEdit: false });
    };

    editHandler = (record) => {

        this.setState({ isloading: true })
        getDataById(record.ID_RESERVATION).then(res => {

            let o = res.data.results[0];
            //console.log("oooo", o)
            let aux;
            let t;
            let array_capacity = [];

            if (o.TRANSPORTE === 'Sedan' || o.TRANSPORTE === 'Sedan/Sienna') {
                aux = Places.units[0].capacity;
                t = 'Sedan';
            } else if (o.TRANSPORTE === 'Hiace' || o.TRANSPORTE === 'Hiace/Transit') {
                aux = Places.units[2].capacity;
                t = 'Hiace';
            } else if (o.TRANSPORTE === 'Suburban' || o.TRANSPORTE === 'Suburban/Sienna') {
                aux = Places.units[1].capacity;
                t = 'Suburban';
            } else if (o.TRANSPORTE === 'Limo') {
                aux = Places.units[3].capacity;
                t = 'Limo';
            }

            for (let i = 0; i < aux; i++) {
                array_capacity.push(i + 1);
            }

            let bool_dep = true;
            if (o.FECHA_SALIDA === "N/A") {
                bool_dep = false;
            } else {
                this.setState({
                    departure_date_selected: moment(moment(o.FECHA_SALIDA, "DD/MM/YYYY")).toDate(),
                })
            }

            //----------------------------------------------------------------
            let p1 = {};
            Places.zones.forEach((item) => {
                item.options.forEach(ob => {
                    if (ob.label === o.HOTEL) {
                        p1 = ob;
                    }
                })
            });
            let p2 = {};
            Places.zones.forEach((item) => {
                item.options.forEach(ob => {
                    if (ob.label === o.HOTEL_DESTINO) {
                        p2 = ob;
                    }
                })
            });

            this.setState({
                selected_res: res.data.results[0],
                arrival_date_selected: moment(moment(o.FECHA_LLEGADA, "DD/MM/YYYY")).toDate(),
                partner_id: o.AGENTE_NOMBRE,
                reservation: o.ID,
                agent_id: o.AGENTE_ID,
                client: o.NOMBRE + " ",
                email: o.CORREO + " ",
                transport: o.TRANSPORTE,
                passengers: o.NPASAJEROS,
                arrival_hotel: o.HOTEL,
                arrival_date: o.FECHA_LLEGADA,
                arrival_time: o.HORA_IN,
                arrival_flight: o.VUELO_IN,
                arrival_airline: o.AEROLINEA_IN,
                aux_arrival_airline: Places.airlines.filter(item => { return o.AEROLINEA_IN == item.value }),
                hasdeparture: bool_dep,
                departure_hotel: o.HOTEL_EXTRA,
                departure_date: o.FECHA_SALIDA,
                departure_time: o.HORA_OUT,
                departure_flight: o.VUELO_OUT,
                departure_airline: o.AEROLINEA_OUT,
                aux_departure_airline: Places.airlines.filter(item => { return o.AEROLINEA_OUT == item.value }),
                observations: o.COMENTARIOS,
                extra_charge: false,
                capacity: array_capacity,
                place1: p1,
                place2: p2,
                object: o,
                hotel_destination: o.HOTEL_DESTINO,
                id_edit: record.ID,
                hotel_extra: o.HOTEL_EXTRA,
                phone: o.TELEFONO
            }, () => {
                this.setState({ isloading: false }, () => {
                    this.setState({ modalActionEdit: true });
                })
            })
        });
    };

    handleEditChange = (e) => {

        //console.log(e.target.name, e.target.value)

        this.setState({
            [e.target.name]: e.target.value,
        });

        if (e.target.name === 'transport') {

            let unit_selected = Places.units.filter((item) => {
                return item.label === e.target.value;
            });

            let array_capacity = [];
            for (let i = 0; i < unit_selected[0].capacity; i++) {
                array_capacity.push(i + 1);
            }

            this.setState({ capacity: array_capacity });
        }
    };

    handleArrivalHotel = (option) => {
        this.setState({ arrival_hotel: option.label });
    };

    handleDestinationHotel = (option) => {
        this.setState({ hotel_destination: option.label });
    };

    handleChangeAirlineArrival = selectedOption => {
        this.setState({ arrival_airline: selectedOption.value });
    };

    handleChangeAirlineDeparture = selectedOption => {
        this.setState({ departure_airline: selectedOption.value });
    };

    toggle = () => {
        this.setState({
            modalIsOpen: !this.state.modalIsOpen,
        })
    };

    toggleEdit = () => {
        this.setState({
            modalActionEdit: !this.state.modalActionEdit,
        })
    };

    handleUpdate = () => {

        this.setState({ isloading: true });
        let data = this.state.object;
        this.setState({ object: {} });
        //console.log("v data", data)

        data.TRANSPORTE = this.state.transport;
        data.NPASAJEROS = this.state.passengers;
        data.HOTEL = this.state.arrival_hotel;
        data.FECHA_LLEGADA = this.state.arrival_date;
        data.HORA_IN = this.state.arrival_time;
        data.VUELO_IN = this.state.arrival_flight;
        data.AEROLINEA_IN = this.state.arrival_airline;
        data.HOTEL_DESTINO = this.state.hotel_destination;
        data.FECHA_SALIDA = this.state.departure_date;
        data.HORA_OUT = this.state.departure_time;
        data.VUELO_OUT = this.state.departure_flight;
        data.AEROLINEA_OUT = this.state.departure_airline;
        data.COMENTARIOS = this.state.observations;
        data.OBSERVACIONES = this.state.observations;
        data.HOTEL_EXTRA = this.state.hotel_extra;
        data.NOMBRE = this.state.client;
        data.CORREO = this.state.email;
        data.TELEFONO = this.state.phone;

        //console.log("para editar", data)
        ApiPutReservation(data.ID_RESERVATION, data).then(res => {

            this.setState({ isloading: false });
            this.closeModalEdit();

            getData().then((res) => {
                /** Se comento en envio de correo al hacer una edicion de la reservacion */
                //this.sendReservationEmail();
                let reserves = [];
                res.data.results.forEach((item) => {

                    let r = {
                        id: item.ID_RESERVATION,
                        ID: item.ID,
                        REGISTRATION_DATE: item.FECHA_REGISTRO,
                        AGENTE_ID: item.AGENTE_ID,
                        PARTHER_ID: item.AGENTE_NOMBRE,
                        ID_RESERVATION: item.ID,
                        NAME: item.NOMBRE + ' ',
                        EMAIL: item.CORREO,
                        PHONE: item.TELEFONO,
                        PICKUP_LOCATION: item.HOTEL,
                        DESTINATION: item.HOTEL_DESTINO,
                        ARRIVAL_DATE: item.FECHA_LLEGADA,
                        ARRIVAL_AIRLINE: item.AEROLINEA_IN,
                        ARRIVAL_FLIGHT: item.VUELO_IN,
                        ARRIVAL_TIME: item.HORA_IN,
                        DEPARTURE_DATE: item.FECHA_SALIDA,
                        DEPARTURE_AIRLINE: item.AEROLINEA_OUT,
                        DEPARTURE_FLIGHT: item.VUELO_OUT,
                        DEPARTURE_TIME: item.HORA_OUT,
                        DEPARTURE_HOTEL: item.HOTEL_EXTRA,
                        ZONE: (item.ZONA == 'Zone 0') ? 'Airport SJD' : item.ZONA,
                        TRANSPORT: item.TRANSPORTE,
                        PASSENGERS: item.NPASAJEROS,
                        COST: item.COSTO,
                        PAID_OUT: item.CONFIRMAR,
                        COMMENTS: item.COMENTARIOS,
                        AUTH_CODE: item.PAGO
                    };

                    reserves.push(r);

                });
                this.setState({ reservations: reserves, });

            });

        }).catch(e => {
            console.log(e);
        })
    };

    sendReservationEmail = async (record = null, resend) => {

        let data;

        if (record == null) {
            this.setState({ isloading: true })

            data = parserEmailEdit(this.state);
            data.resend = resend;
            //console.log(data)
            sendEmail(data).then((res) => {
                this.setState({ isloading: false });
                Swal.fire('Your reservation was updated successful', 'And your email was resent', 'success');
            }).catch(e => {
                console.log(e);
            });

        } else {

            Swal.fire({
                title: 'Are you sure you want to resend email again ?',
                text: 'this will send an email to all email attached',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, send!',
                cancelButtonText: 'No, cancel'
            }).then((result) => {
                this.setState({ isloading: true })

                if (result.value) {

                    getDataById(record.ID_RESERVATION).then(res => {

                        let record_selected = res.data.results[0];
                        data = parserResendEmail(record_selected);
                        data.resend = resend;

                        sendEmail(data).then((res) => {
                            this.setState({ isloading: false });
                            Swal.fire('¡Success!', 'Your email was resent', 'success');
                        }).catch(e => {
                            console.log(e);
                        });
                    });

                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    this.setState({ isloading: false })
                }
            });

        }
    };

    render() {

        let trip_type = '';
        
        if( this.state.selected_res.FECHA_SALIDA === "N/A" ||  this.state.selected_res.FECHA_SALIDA === "" || this.state.selected_res.FECHA_LLEGADA === "N/A" || this.state.selected_res.FECHA_LLEGADA === "") {
            trip_type = "One Way"
        } else {
            trip_type = "Round Trip"
        }
        
        const { HOTEL_DESTINO, HOTEL_EXTRA } = this.state.selected_res;

        return (
            <Fragment>
                <LoadingOverlay
                    active={this.state.isloading}
                    spinner
                    styles={loaderStyles}
                    text='We are processing your request...'>
                    <div id="booking-form" className="booking-form">

                        <Header></Header>
                        <div className="full" id="breadcrumbs">
                            <div className="grid-container">
                                <div className="contenedor-flex grid-x grid-padding-x"></div>
                            </div>
                        </div>

                        <div className="grid-container">
                            <div className="grid-x grid-padding-x grid-padding-y">
                                <div className="medium-5 cell align-self-center pl-3">
                                    <h1>REGISTERED RESERVATIONS</h1>
                                </div>
                                {/* <div className="medium-5 cell">
                                    <div className="radio-buttons">
                                        <label>Select Filter Data
                                            <select onChange={this.handleChange} name="filterOption">
                                                <option value="register">Register Date</option>
                                                <option value="arrival">Arrival Date</option>
                                                <option value="departure">Departure Date</option>
                                            </select>
                                        </label>
                                    </div>
                                </div> */}
                                <div className="medium-2 cell">
                                    <label>Initial Date
                                        <DatePicker selected={this.state.date_init}
                                            onChange={this.handleDateinChange} required></DatePicker>
                                    </label>
                                </div>
                                <div className="medium-2 cell">
                                    <label>Date End
                                        <DatePicker selected={this.state.date_end}
                                            onChange={this.handleDateEndChange} required></DatePicker>
                                    </label>
                                </div>
                                <div className="medium-1 cell align-self-bottom d-flex justify-content-end">
                                    <button type='submit' className="button btn-primary" onClick={this.handleSubmit}>SEARCH</button>
                                </div>
                                <div className="medium-2 cell align-self-bottom d-flex justify-content-end">
                                    <ExcelExport data={this.state.reservations}></ExcelExport>
                                </div>
                            </div>
                        </div>

                        <div className="grid-container">
                            <div className="grid-x grid-padding-x grid-padding-y">

                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="grid-x grid-padding-x grid-padding-y">

                                </div>
                            </form>
                            <div className="grid-x grid-padding-x grid-padding-y b-white">
                                <div className="small-12 cell">
                                    <TableReservations
                                        openModalEdit={this.openModalEdit}
                                        handler={this.handleDetails}
                                        editHandler={this.editHandler}
                                        sendReservationEmail={this.sendReservationEmail}
                                        records={this.state.reservations}
                                        userlvl={this.state.userlvl}>
                                    </TableReservations>
                                </div>
                            </div>
                        </div>
                        <Footer></Footer>

                    </div>
                </LoadingOverlay>
                {/**    MODAL DE REACT STRAP 1  SHOW INFO */}
                <Modal isOpen={this.state.modalIsOpen} toggle={this.toggle} size="lg" scrollable={true}>
                    <ModalHeader toggle={this.toggle}>RESERVATION ID: {this.state.selected_res.ID}</ModalHeader>
                    <ModalBody>
                        <div className="grid-container">
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 medium-12 cell text-left">
                                    <div className="grid-x">
                                        <div className="small-12 medium-6 cell info-fix">
                                            {(this.state.selected_res.rate_control == true) ? <h4>Promotion: <span className="promotion_text">{this.state.selected_res.rcontrol.DESCUENTO}%</span> off</h4> : null}
                                            {(this.state.selected_res.rate_control == true) ? <h5>Rate: <span className="line-through">${this.state.selected_res.rcontrol.TOTAL}  USD</span></h5> : null}
                                            <h4 autoFocus className="font-weight-bold card-title">TOTAL: ${this.state.selected_res.COSTO} {this.state.selected_res.MONEDA}</h4>
                                            <hr />
                                            <p><b>Trip type:</b> <span className="pax_num">{trip_type}</span></p>
                                            <p><b>Passengers:</b> <span className="pax_num">{this.state.selected_res.NPASAJEROS}</span></p>
                                            <p><b>Transport Selected:</b> <span>{this.state.selected_res.TRANSPORTE == 'Sedan' ? 'Sedan/Sienna' : this.state.selected_res.TRANSPORTE}</span></p>
                                            <p><b>Pickup Location:</b> <span>{this.state.selected_res.HOTEL}</span></p>
                                            <p><b>Destination:</b> <span>{this.state.selected_res.HOTEL_DESTINO}</span></p>
                                            <p><b>Pickup Departure Location:</b> <span>{this.state.selected_res.HOTEL_EXTRA}</span></p>
                                            <hr />
                                        </div>
                                        <div className="small-12 medium-6">
                                            <img className="" src={this.state.selected_res.image} alt="transport selected" />
                                        </div>
                                    </div>
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-6 cell info-fix">
                                            <h5>Contact / Traveler Information:</h5>
                                            <div id="fullnameTraveler">
                                                <p><b>Full name: </b> <span className="fullname">{`${this.state.selected_res.NOMBRE} ${this.state.selected_res.APELLIDO}`}</span></p>
                                            </div>
                                            <div id="phoneTraveler">
                                                <p><b>Phone: </b><span className="phone">{this.state.selected_res.TELEFONO}</span></p>
                                            </div>
                                            <div id="emailTraveler">
                                                <p><b>E-mail: </b><span className="email">{this.state.selected_res.CORREO}</span></p>
                                            </div>
                                        </div>
                                        <div className="small-6 cell">
                                            <h5>Comments:</h5>
                                            <p>{this.state.selected_res.COMENTARIOS}</p>
                                        </div>
                                        <div className="small-12 medium-6 cell info-fix">
                                            <hr />
                                            <h5>
                                                {HOTEL_DESTINO === "AIRPORT SJD" && trip_type === "One Way"
                                                    ? "Departure Information:"
                                                    : "Arrival Information:"}{" "}
                                            </h5>
                                            <div className="grid-y">
                                                <p><b>Date / Time:</b> <span>{this.state.selected_res.FECHA_LLEGADA} {this.state.selected_res.HORA_IN}</span></p>
                                                <p><b>Flight Number: </b><span>{this.state.selected_res.VUELO_IN}</span></p>
                                                <p><b>Airline Name: </b><span>{this.state.selected_res.AEROLINEA_IN}</span></p>
                                            </div>
                                        </div>
                                        {
                                            this.state.selected_res.FECHA_SALIDA === 'N/A' ? <div className="small-12"></div> :
                                                <div className="small-12 medium-6 cell info-fix">
                                                    <hr />
                                                    <h5>Departure Information:</h5>
                                                    <div className="grid-y">
                                                        <p><b>Date / Time: </b><span>{this.state.selected_res.FECHA_SALIDA} {this.state.selected_res.HORA_OUT}</span></p>
                                                        <p><b>Flight Number: </b><span>{this.state.selected_res.VUELO_OUT}</span></p>
                                                        <p><b>Airline Name: </b><span>{this.state.selected_res.AEROLINEA_OUT}</span></p>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-primary" onClick={this.toggle}> <i className="fa fa-check-circle"></i>  &nbsp; ACEPTAR</button>
                    </ModalFooter>
                </Modal>
                {/**    MODAL DE REACT STRAP 1 EDIT RESERVATION */}
                <Modal isOpen={this.state.modalActionEdit} toggle={this.toggleEdit} size="lg" className="modal-modify" scrollable={true} backdrop={'static'}>
                    <ModalHeader toggle={this.toggleEdit}>RESERVATION ID: {this.state.id_edit}</ModalHeader>
                    <ModalBody>
                        <div className="grid-container">
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 medium-6 cell text-left">
                                    <p><b>Partner ID:</b>
                                        <input type="text" autoComplete="off" disabled name='parnter_id' value={this.state.partner_id} onChange={this.handleEditChange} /></p>
                                </div>
                                <div className="small-12 medium-6 cell text-left">
                                    <p><b>Agent's ID:</b></p>
                                    <input type="text" autoComplete="off" disabled name='agent_id' value={this.state.agent_id} onChange={this.handleEditChange} />
                                </div>
                                <div className="small-12 medium-6 cell text-left">
                                    <p><b>Transport:</b></p>
                                    <select id="transport_id" value={this.state.transport} name="transport" onChange={this.handleEditChange}>
                                        {
                                            this.state.units.map((item, pos) => {
                                                return <option key={pos} value={item.label}>{item.text}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="small-12 medium-6 cell text-left">
                                    <p><b>Passengers:</b>
                                        <select defaultValue={this.state.passengers} name="passengers" onChange={this.handleEditChange}>
                                            {
                                                this.state.capacity.map((item, pos) => {
                                                    return <option key={pos} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </p>
                                </div>
                                <div className="small-12 medium-6 cell text-left">
                                    <p><b>Traveler full name:</b></p>
                                    <input type="text" autoComplete="off" name='client'
                                        value={this.state.client} onChange={this.handleEditChange} />
                                </div>
                                <div className="small-12 medium-6 cell text-left">
                                    <p><b>Traveler Email:</b></p>
                                    <input type="text" autoComplete="off" name='email'
                                        value={this.state.email} onChange={this.handleEditChange} />
                                </div>
                                <div className="small-12 medium-6 cell text-left pickupLocation">
                                    <p><b>Pickup Location:</b></p>
                                    <Select className="select-search"
                                        defaultValue={this.state.place1}
                                        onChange={this.handleArrivalHotel}
                                        options={Places.zones}>
                                    </Select>
                                </div>
                                <div className="small-12 medium-6 cell">
                                    <p><b>Destination:</b></p>
                                    <Select className="select-search"
                                        defaultValue={this.state.place2}
                                        onChange={this.handleDestinationHotel}
                                        options={Places.zones}
                                    ></Select>
                                </div>
                                <div className="small-12 medium-6 cell">
                                    <p><b>Traveler's Phone:</b></p>
                                    <input type="text" autoComplete="off" name='phone'
                                        value={this.state.phone} onChange={this.handleEditChange} />
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <h4>Arrival Information</h4>
                                </div>
                                <div className="small-12 medium-6 cell text-left">
                                    <b>Pickup date:</b>
                                    <DatePicker
                                        onChange={this.handleDateinEditChange}
                                        selected={this.state.arrival_date_selected}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date(Date.now())}>
                                    </DatePicker>
                                </div>
                                <div className="small-12 medium-6 cell text-left">
                                    <p><b>Pickup time:</b></p>
                                    <input type="text" name='arrival_time' value={this.state.arrival_time}
                                        onChange={this.handleEditChange} />
                                </div>
                                <div className="small-12 medium-6 cell text-left">
                                    <b>Airline's name:</b>
                                    <Select
                                        defaultValue={this.state.aux_arrival_airline}
                                        className="select-search"
                                        onChange={this.handleChangeAirlineArrival}
                                        options={this.state.airlines}
                                    />
                                </div>
                                <div className="small-12 medium-6 cell text-left">
                                    <p><b>Flight's number:</b></p>
                                    <input type="text" name='arrival_flight' value={this.state.arrival_flight} onChange={this.handleEditChange} />
                                </div>
                            </div>
                            {
                                this.state.hasdeparture ?
                                    <div className="grid-x grid-padding-x">
                                        <div className="small-12 cell">
                                            <h4>Departure Information</h4>
                                        </div>
                                        <div className="small-12 cell">
                                            <p><b>Pickup Location:</b></p>

                                            {
                                                HOTEL_EXTRA !== '' && HOTEL_EXTRA !== 'N/A' ?
                                                    <input type="text" autoComplete="off" name="hotel_extra" value={this.state.hotel_extra} onChange={this.handleEditChange} />
                                                    : <Select className="select-search"
                                                        defaultValue={this.state.place2}
                                                        onChange={this.handleArrivalHotel}
                                                        options={Places.zones} />
                                            }

                                        </div>
                                        <div className="small-12 medium-6 cell text-left">
                                            <b>Pickup date:</b>
                                            <DatePicker
                                                onChange={this.handleDateOutEditChange}
                                                selected={this.state.departure_date_selected}
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date(Date.now() + (3600 * 1000 * 24))}
                                            ></DatePicker>
                                        </div>
                                        <div className="small-12 medium-6 cell text-left">
                                            <p><b>Pickup time:</b></p>
                                            <input type="text" autoComplete="off" name="departure_time" value={this.state.departure_time} onChange={this.handleEditChange} />
                                        </div>
                                        <div className="small-12 medium-6 cell text-left">
                                            <p><b>Flight' number:</b>
                                                <input type="text" autoComplete="off" name="departure_flight" value={this.state.departure_flight} onChange={this.handleEditChange} />
                                            </p>
                                        </div>
                                        <div className="small-12 medium-6 cell text-left">
                                            <b>Airline's name:</b>
                                            <Select
                                                defaultValue={this.state.aux_departure_airline}
                                                className="select-search"
                                                onChange={this.handleChangeAirlineDeparture}
                                                options={this.state.airlines} />
                                        </div>
                                    </div>
                                    : null}
                            {this.state.hasdeparture ? <hr /> : null}
                            <div className="grid-x grid-padding-x">
                                <div className="small-12 cell">
                                    <p><b>Observations:</b>
                                        <textarea rows="7" width="100" value={this.state.observations} onChange={this.handleEditChange} name="observations"></textarea>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-danger" onClick={this.toggleEdit}>CLOSE</button>
                        <button type="button" className="btn btn-secondary" onClick={this.handleUpdate} >UPDATE</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}

