import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import "font-awesome/css/font-awesome.min.css";

class TableReservations extends Component {

    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "ID",
                text: "ID Reserva",
                align: "center",
                sortable: true,
            },
            {
                key: "REGISTRATION_DATE",
                text: "Registro",
                align: "center",
                sortable: true,
            },
            {
                key: "AGENTE_ID",
                text: "ID Agente",
                align: "center",
                sortable: true,
            },
            {
                key: "NAME",
                text: "Cliente",
                align: "center",
                sortable: true,
            },
            {
                key: "ARRIVAL_DATE",
                text: "Llegada",
                align: "center",
                sortable: true,
            }, {
                key: "ARRIVAL_FLIGHT",
                text: "Vuelo",
                align: "center",
                sortable: true,
            }, {
                key: "DEPARTURE_DATE",
                text: "Salida",
                align: "center",
                sortable: true,
            }, {
                key: "TRANSPORT",
                text: "Transporte",
                align: "center",
                sortable: true,
            }, {
                key: "AUTH_CODE",
                text: "Auth Code",
                align: "center",
                sortable: true,
            }, {
                key: "action",
                text: "Opciones",
                width: 125,
                align: "center",
                className: "action",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment key={record.ID}>
                            <button className="btn btn-dark btn-sm"
                                style={{ marginRight: '4px' }} onClick={() => this.props.handler(record)}>
                                <i className="fa fa-info-circle"></i>
                            </button>

                            { this.props.userlvl === '1' ? <button onClick={() => this.props.editHandler(record)} className="btn btn-info btn-sm"
                                style={{ marginRight: '4px' }}>
                                <i className="fa fa-edit"></i>
                            </button> : <div></div>}

                            <button onClick={() => this.props.sendReservationEmail(record, false)} className="btn btn-warning btn-sm"
                                style={{ marginRight: '4px' }}>
                                <i className="fa fa-paper-plane"></i>
                            </button>

                            <button onClick={() => this.props.sendReservationEmail(record, true)} className="btn btn-danger btn-sm"
                                style={{ marginRight: '4px' }}>
                                <i className="fa fa-paper-plane"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100, 200],
            button: { excel: false },
            sort: { column: "ID_RESERVATION", order: "desc" },
        }
        this.state = {
            records: this.props.records
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        return {
            records: props.records,
        }
    }

    render() {
        return (
            <ReactDatatable
                className="table stack b-white hover table-bordered"
                key={'table11'}
                config={this.config}
                records={this.state.records}
                columns={this.columns}
            />
        );
    }
}

export default TableReservations;
