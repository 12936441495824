import React, { Component } from 'react';

class footer extends Component {
    render() {
        return (
            <footer className="grid-containerfull ">
                <br />
                <div className="grid-container">
                    <div className="grid-x grid-padding-y grid-padding-x align-self-middle">
                        <div className="small-12 medium-8 cell medium-text-left">
                            <p>Transroute Cabo Transportation &copy; <br /> 2020 - Los Cabos, Baja California Sur, México</p>
                        </div>
                        <div className="small-12 medium-4 cell medium-text-right">
                            <small>Development by: <a href="https://innversasolutions.com/" target="_blank">Innversa Solutions.</a></small>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default footer;
