import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {handlelogout, getUserLvl} from '../../session/session_controller';

class header extends Component {

    constructor(){
        super();
        this.state={
            userlvl: getUserLvl(),
        }
    }

    handleClickLogOut = () => {
        handlelogout();
    }

    

    render() {
        return (
            <div id="top-bar" className="full align-self-bottom">
                <div className="grid-container">
                    <div className="grid-x grid-padding-x grid-padding-y">
                        <a className="small-12 medium-3 large-6 cell small-only-text-center  medium-text-left large-text-left" href="/reserve">
                            <img className="logo-header" height="30" src={process.env.PUBLIC_URL + '/logo-2020.svg'} alt="RCI" />
                        </a>
                        {/* el siguiente menu solo aparecerá en el formulario y las tablas */}
                        <div className="small-12 medium-9 large-6 cell align-self-middle text-right large-text-right">
                            <ul className="menu align-right">
                                <li><a href="/reserve">RESERVE</a></li>
                                {this.state.userlvl === '3' ? <div></div> : <li><a href="/reservations">RESERVATIONS</a></li>}
                                {this.state.userlvl === '3' ? <div></div> : <li><a href="/fees">FEES</a></li>}
                                <li><a href="/" onClick={this.handleClickLogOut} ><FontAwesomeIcon icon="sign-out-alt" /> Log out</a></li>
                            </ul>
                        </div>
                        {/* fin del formulario */}
                    </div>
                </div>
            </div>
        );
    }
}

export default header;
