import React, { Component } from "react";
import {
  validateToken,
  handlelogout,
  getUserLvl,
} from "./../../session/session_controller";
import {
  ApiCreateReservation,
  ApiGetRates,
} from "./../../services/reservation";
import { ApiCreateRControl } from "../../services/rates_control";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./../utils/header";
import Footer from "./../utils/footer";
import ReservationSummary from "./reservationsummary";
import ModalContent from "./complements/modalContent";
import { Modal, ModalBody } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Places from "./../../places.json";
import Swal from "sweetalert2";
import Loading from "react-loading-overlay";
import { sendEmail } from "./../../api_controller/email_controller";
import { parserEmail, parserInsert } from "../utils/parser";

const nowDate = new Date();
const nowDateAux = new Date();

const loaderStyles = {
  overlay: (base) => ({
    ...base,
    background: "rgba(0, 0, 0, 0.9)",
  }),
};

const getArrayOfCapacity = (number) => {
  let array = [];
  for (let i = 0; i < number; i++) {
    array.push(i + 1);
  }
  return array;
};

export default class index extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      units: Places.units,
      airlines: Places.airlines,
      startDateArrival: nowDate.setDate(nowDate.getDate() + 1),
      startDateDeparture: nowDate.setDate(nowDate.getDate() + 1),
      modalConfirmControl: false,
      modalActive: false,
      //Datos del formulario
      rate: 0,
      agent_id: "",
      member_id: "",
      payment_auth_code: "",
      trip_type: "One Way",
      pickup: "",
      pickup_zone: "",
      destination: "",
      destination_zone: "",
      transport: Places.units[0].label,
      img_transport: Places.units[0].image,
      capacity: getArrayOfCapacity(Places.units[0].capacity),
      passengers: "1",
      arrival_datetime: moment(nowDateAux.setDate(nowDateAux.getDate() + 1)).format(),
      arrival_time: "",
      arrival_flight: "",
      arrival_airline: "",
      departure_datetime: moment(nowDateAux.setDate(nowDateAux.getDate() + 1)).format(),
      departure_time: "",
      departure_flight: "",
      departure_airline: "",
      contact_name: "",
      contact_phone: "",
      contact_email: "",
      observations: "",
      hotel_extra: "",
      //Nivel del usuario
      userlvl: getUserLvl(),
    };

    validateToken();
  }

  handleClickLogOut = () => {
    handlelogout();
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "transport") {
      let unit_selected = Places.units.filter((item) => {
        return item.label === e.target.value;
      });

      let array_capacity = [];
      for (let i = 0; i < unit_selected[0].capacity; i++) {
        array_capacity.push(i + 1);
      }

      this.setState(
        {
          capacity: array_capacity,
          img_transport: unit_selected[0].image,
        },
        () => {
          this.getRate();
        }
      );
    }

    if (e.target.name == "trip_type") {
      this.getRate();
    }
  };

  handleDestinationChange = (option) => {
    this.setState(
      { destination: option.label, destination_zone: "Zone " + option.zone },
      () => {
        this.getRate();
      }
    );
  };

  handlePickupChange = (option) => {
    this.setState(
      { pickup: option.label, pickup_zone: "Zone " + option.zone },
      () => {
        this.getRate();
      }
    );
  };

  handleChangeDateArrival = (date) => {
    this.setState({ startDateArrival: date });
    this.setState({ arrival_datetime: moment(date).format() });
  };

  handleChangeDateDeparture = (date) => {
    this.setState({ startDateDeparture: date });
    this.setState({ departure_datetime: moment(date).format() });
  };

  handleChangeAirlineArrival = (selectedOption) => {
    this.setState({ arrival_airline: selectedOption.value });
  };

  handleChangeAirlineDeparture = (selectedOption) => {
    this.setState({ departure_airline: selectedOption.value });
  };

  sendReservationEmail = (id) => {
    let data = parserEmail(this.state, id);

    sendEmail(data)
      .then((res) => {
        this.setState({ modalActive: false });

        Swal.fire(
          "Your reservation was created successful",
          "And your email was sent - redirecting in 3 seconds to see reservation details",
          "success"
        );

        setTimeout(() => {
          window.location.href = `/reservations-details/${id}`;
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  cleanForm = () => {
    this.setState({
      startDateArrival: nowDate.setDate(nowDate.getDate() + 1),
      startDateDeparture: nowDate.setDate(nowDate.getDate() + 1),
      modalConfirmControl: false,
      //Datos del formulario
      rate: 0,
      agent_id: "",
      member_id: "",
      payment_auth_code: "",
      trip_type: "One Way",
      pickup: "",
      pickup_zone: "",
      destination: "",
      destination_zone: "",
      transport: Places.units[0].label,
      img_transport: Places.units[0].image,
      capacity: [1, 2, 3, 4, 5, 6],
      passengers: "1",
      arrival_datetime: moment(nowDate.setDate(nowDate.getDate() + 1)).format(),
      arrival_flight: "",
      arrival_airline: "",
      departure_datetime: moment(
        nowDate.setDate(nowDate.getDate() + 1)
      ).format(),
      departure_flight: "-",
      departure_airline: "-",
      contact_name: "",
      contact_phone: "",
      contact_email: "",
      observations: "",
      hotel_extra: "",
    });
  };

  getRate = () => {
    if (this.state.pickup_zone !== "" && this.state.destination_zone != "") {
      ApiGetRates(
        this.state.pickup_zone,
        this.state.destination_zone,
        this.state.transport
      ).then((resp) => {
        if (resp.data.response.success === 200) {
          this.setState({
            rate:
              this.state.trip_type === "One Way"
                ? resp.data.response.data[0].rate
                : resp.data.response.data[0].rate_round_trip,
          });
        } else {
          this.setState({ rate: 0 });
        }
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    
    let data_validate = parserInsert(this.state);
    let correct = true;

    if (data_validate.COSTO != 0) {
      for (let key in data_validate) {

        if(key !== 'HOTEL_EXTRA'){
          if ( data_validate[key] == "" ||data_validate[key] == null || data_validate[key] == undefined ) {
            correct = false;
          }
        }else{
          data_validate[key] = 'N/A';
        }
        
      }

      if (correct) {
        this.showModal();
      } else {
        Swal.fire(
          "Warning",
          "Please add all parameters to create a reservation.",
          "warning"
        );
      }
    } else {
      Swal.fire(
        "Warning",
        "Please select correct zones to get a rate",
        "warning"
      );
    }
  };

  saveReservation = () => {
    let data = parserInsert(this.state);
    console.log("_data to insert", data);

    if (data.rate !== 0) {
      this.closeModal();
      this.setState({ modalActive: true });
      ApiCreateReservation(data)
        .then(async (resp) => {
          if (resp.data.status !== 500) {
            // aqui guardamos el control de la tarifa
            let RControlData = {
              ID_RESERVATION: resp.data.results.insertId,
              DESCUENTO: 10,
              TOTAL: this.state.rate,
              TOTAL_DESCUENTO: data.COSTO,
            };

            let _rcontrol = await ApiCreateRControl(RControlData);
            this.sendReservationEmail(data.ID);
            this.cleanForm();
          } else {
            Swal.fire("Warning", resp.data.results.sqlMessage, "warning");
          }
        })
        .catch((error) => {
          console.log("Error", error);
          this.setState({ modalActive: false });
        });
    } else {
      Swal.fire("Warning", "Please select correct zones", "warning");
    }
  };

  closeModal = () => {
    this.setState({ modalConfirmControl: false });
  };

  showModal = () => {
    this.setState({ modalConfirmControl: true });
  };

  render() {

	const { destination, trip_type } = this.state;

    return (
      <Loading
        active={this.state.modalActive}
        spinner
        text="Creating your reservation..."
        styles={loaderStyles}
      >
        <div id="booking-form" className="booking-form">
          <Header></Header>
          <div className="full" id="breadcrumbs">
            <div className="grid-container">
              <div className="contenedor-flex grid-x grid-padding-x"></div>
            </div>
          </div>
          <div className="grid-container">
            <div
              id="form-traveler"
              className="grid-x grid-padding-x grid-padding-y form-traveler"
            >
              <div className="medium-12 cell">
                <h1>RESERVATION FORM</h1>
              </div>
              <div className="small-12 medium-8 cell">
                <form onSubmit={this.onSubmit}>
                  <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">
                      {this.state.error ? (
                        <div className="alert callout alert">
                          <p id="alert" className="big">
                            <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                            There are some errors in your form.
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="small-12 cell">
                      <h4>RCI's Agent, Member and Payment Code:</h4>
                      <div className="grid-x grid-padding-x">
                        <div className="small-6 large-4 cell">
                          <label>
                            {" "}
                            <span>Agent's ID:</span>
                            <div className="input-group">
                              <input
                                className="input-text"
                                id="agent_id"
                                onChange={this.handleChange}
                                name="agent_id"
                                value={this.state.agent_id}
                                type="text"
                                placeholder="Type Agent's ID"
                                autoComplete="on"
                                required
                                autoComplete="off"
                              />
                            </div>
                          </label>
                        </div>
                        <div className="small-6 large-4 cell">
                          <label>
                            {" "}
                            <span>Member's ID:</span>
                            <div className="input-group">
                              <input
                                className="input-text"
                                id="member_id"
                                onChange={this.handleChange}
                                name="member_id"
                                value={this.state.member_id}
                                type="text"
                                placeholder="Type Member's ID"
                                autoComplete="off"
                                required
                                autoComplete="off"
                              />
                            </div>
                          </label>
                        </div>
                        <div className="small-12 large-4 cell">
                          <label>
                            {" "}
                            <span>Payment Authorization Code:</span>
                            <div className="input-group">
                              <input
                                className="input-text"
                                id="payment_auth_code"
                                onChange={this.handleChange}
                                name="payment_auth_code"
                                value={this.state.payment_auth_code}
                                type="text"
                                placeholder="Payment Code"
                                autoComplete="off"
                                required
                                autoComplete="off"
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="small-12 cell">
                      <h4>
                        Select Trip Type, Passengers Number, Vehicle, Pickup
                        Location, Destination:
                      </h4>
                      <div className="grid-x grid-padding-x">
                        <div className="small-6 medium-4 cell">
                          <label>
                            SELECT TRIP TYPE:
                            <div className="input-group">
                              <span className="bg-icons input-group-time">
                                <FontAwesomeIcon icon="route" />
                              </span>
                              <select
                                id="trip_type"
                                value={this.state.trip_type}
                                name="trip_type"
                                onChange={this.handleChange}
                              >
                                <option value="One Way">One Way</option>
                                <option value="Round Trip">Round Trip</option>
                              </select>
                            </div>
                            <label
                              className="form-error fullname-error"
                              data-form-error-for="fullname"
                            >
                              Full Name is invalid: 4 character minimum or only
                              text required.
                            </label>
                          </label>
                        </div>
                        <div className="small-12 medium-4 cell">
                          <label>
                            SELECT VEHICLE TRANSPORT:
                            <div className="input-group">
                              <span className="bg-icons input-group-time">
                                <FontAwesomeIcon icon="shuttle-van" />
                              </span>
                              <select
                                id="transport_id"
                                value={this.state.transport}
                                name="transport"
                                onChange={this.handleChange}
                              >
                                {this.state.units.map((item, pos) => {
                                  return (
                                    <option key={pos} value={item.label}>
                                      {item.text}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <label
                              className="form-error fullname-error"
                              data-form-error-for="fullname"
                            >
                              Full Name is invalid: 4 character minimum or only
                              text required.
                            </label>
                          </label>
                        </div>
                        <div className="small-6 medium-4 cell">
                          <label>
                            PASSENGERS NUMBER:
                            <div className="input-group">
                              <span className="bg-icons input-group-time">
                                <FontAwesomeIcon icon="user-friends" />
                              </span>
                              <select
                                id="pax"
                                value={this.state.passengers}
                                name="passengers"
                                onChange={this.handleChange}
                              >
                                {this.state.capacity.map((item, pos) => {
                                  return (
                                    <option key={pos} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <label
                              className="form-error fullname-error"
                              data-form-error-for="fullname"
                            >
                              Full Name is invalid: 4 character minimum or only
                              text required.
                            </label>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="small-6 medium-6 cell">
                      <label>
                        <span id="location"> SELECT PICKUP LOCATION:</span>
                        <div className="input-group">
                          <span className="bg-icons input-group-time">
                            <FontAwesomeIcon icon="map-marker-alt" />
                          </span>
                          <Select
                            className="select-search"
                            onChange={this.handlePickupChange}
                            options={Places.zones}
                          ></Select>
                        </div>
                      </label>
                    </div>
                    <div className="small-6 medium-6 cell">
                      <label>
                        <span id="destination"> SELECT DESTINATION:</span>
                        <div className="input-group">
                          <span className="bg-icons input-group-time">
                            <FontAwesomeIcon icon="map-marker-alt" />
                          </span>
                          <Select
                            className="select-search"
                            onChange={this.handleDestinationChange}
                            options={Places.zones}
                          ></Select>
                        </div>
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">
                      <h4>Contact / Traveler Information:</h4>
                      <p>Please fill form with traveler information:</p>
                      <label className="fullNameEnter">
                        {" "}
                        Enter the traveler's full name:
                        <div className="input-group">
                          <span className="bg-icons input-group-time">
                            <FontAwesomeIcon icon="user" />
                          </span>
                          <input
                            className="input-text"
                            id="contact_name"
                            value={this.state.contact_name}
                            name="contact_name"
                            onChange={this.handleChange}
                            type="text"
                            placeholder="type traveler name"
                            required
                            autoComplete="off"
                          />
                        </div>
                        <label
                          className="form-error fullname-error"
                          data-form-error-for="fullname"
                        >
                          Full Name is invalid: 4 character minimum or only text
                          required.
                        </label>
                      </label>
                    </div>
                    <div className="small-6 medium-6 cell ">
                      <label className="phoneEnter">
                        Enter the contact's Phone / Cell
                        <div className="input-group">
                          <span className="bg-icons input-group-time">
                            <FontAwesomeIcon icon="phone" />
                          </span>
                          <input
                            className="input-text"
                            id="contact_phone"
                            value={this.state.contact_phone}
                            name="contact_phone"
                            onChange={this.handleChange}
                            type="text"
                            placeholder="enter example: 15417543010"
                            maxLength="13"
                            aria-describedby="phone"
                            aria-errormessage="phone"
                            required
                            autoComplete="off"
                          />
                        </div>
                        <label
                          className="form-error"
                          data-form-error-for="phone"
                        >
                          Phone number invalid;
                        </label>
                        <label
                          className="form-error phone-error"
                          data-form-error-for="phone"
                        >
                          Invalid: min: 10, max: 14 digits
                        </label>
                      </label>
                    </div>
                    <div className="small-6 medium-6 cell">
                      <label className="emailEnter">
                        {" "}
                        Enter the contact's{" "}
                        <br className="show-for-small-only" /> Email:
                        <div className="input-group">
                          <span className="bg-icons input-group-time">
                            <FontAwesomeIcon icon="envelope" />
                          </span>
                          <input
                            id="contact_email"
                            value={this.state.contact_email}
                            name="contact_email"
                            onChange={this.handleChange}
                            type="email"
                            placeholder="type Main Email"
                            autoComplete="off"
                          />
                        </div>
                        <label
                          className="form-error"
                          data-form-error-for="email"
                        >
                          Email format is invalid.
                        </label>
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="grid-x grid-padding-x">
                    <div className="small-12 cell">
                      <h4>
                        <i className="fas fa-plane-arrival"></i> 
                      {destination === "AIRPORT SJD" && trip_type === "One Way"
                        ? "Departure Information:"
                        : "Arrival Information:"}{" "}
                      </h4>
                      <div className="grid-x grid-padding-x">
						<div className="small-12 medium-6 cell">
							<label className="">
							{" "}
							<span>Select Date:</span>
								<div className="input-group">
									<span className="bg-icons input-group-time">
										<FontAwesomeIcon icon="calendar-alt" />
									</span>
									<DatePicker
										selected={this.state.startDateArrival}
										onChange={this.handleChangeDateArrival}
										className={"input-text"}
										dateFormat="MMMM d, yyyy"
										popperPlacement="top-start"
										minDate={
											new Date(Date.now())
										}
										popperModifiers={{
											offset: {
											enabled: true,
											offset: "5px, 10px",
											},
											preventOverflow: {
											enabled: true,
											escapeWithReference: false,
											boundariesElement: "viewport",
											},
										}}
									/>
								</div>
								</label>
						</div>
						<div className="small-12 medium-6 cell">
							<label className="">
								{" "}
								<span>Select Time:</span>
								<div className="input-group">
									<span className="bg-icons input-group-time">
										<FontAwesomeIcon icon="clock" />
									</span>
									<input
										className="input-text"
										id="arrival_time"
                                        name="arrival_time"
                                        value={this.state.arrival_time}
                                        onChange={this.handleChange}
										type="time"
										placeholder="Type Flight's Number"
										required
										autoComplete="off"
									/>
								</div>
							</label>
						</div>
                        <div className="small-12 medium-6 cell">
                        <label className="ArrivalAirlineEnter">
                          {" "}
                          <span> flight's Number:</span>
                          <div className="input-group">
                            <span className="bg-icons input-group-time">
                              <FontAwesomeIcon icon="plane-arrival" />
                            </span>
                            <input
                              className="input-text"
                              id="arrival_flight"
                              value={this.state.arrival_flight}
                              name="arrival_flight"
                              onChange={this.handleChange}
                              type="text"
                              placeholder="Type Flight's Number"
                              required
                              autoComplete="off"
                            />
                          </div>
                        </label>
                        </div>
                        <div className="small-12 medium-6 cell">
                          <label className="ArrivalAirlineEnter">
                            {" "}
                            Airline's name:
                            <div className="input-group">
                            <span className="bg-icons input-group-time">
                              <FontAwesomeIcon icon="plane-arrival" />
                            </span>
                            <Select
                              className="select-search"
                              onChange={this.handleChangeAirlineArrival}
                              options={this.state.airlines}
                            />
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    {this.state.trip_type === "Round Trip" ? (
                      <div className="small-12 cell departure-info">
                        {" "}
                        <hr />
                        <h4>
                          <i className="fas fa-plane-departure"></i> Departure
                          Information:
                        </h4>
                        <div className="grid-x grid-padding-x">
                          <div className="small-12 medium-6 cell">
                            <label className="">
                              {" "}
                              <span>Select Time:</span>
                              <div className="input-group">
                                <span className="bg-icons input-group-time">
                                  <FontAwesomeIcon icon="calendar-alt" />
                                </span>
                                <DatePicker
                                  selected={this.state.startDateDeparture}
                                  onChange={this.handleChangeDateDeparture}
                                  className={"input-text"}
                                  popperPlacement="top-start"
                                  dateFormat="MMMM d, yyyy"
                                  minDate={this.state.startDateArrival}
                                  popperModifiers={{
                                    offset: {
                                      enabled: true,
                                      offset: "5px, 10px",
                                    },
                                    preventOverflow: {
                                      enabled: true,
                                      escapeWithReference: false,
                                      boundariesElement: "viewport",
                                    },
                                  }}
                                />
                              </div>
                            </label>
                          </div>
                          <div className="small-12 medium-6 cell">
                            <label className="DepartTime">
                              {" "}
                              <span> Select time:</span>
                              <div className="input-group">
                                <span className="bg-icons input-group-time">
                                  <FontAwesomeIcon icon="clock" />{" "}
                                </span>
                                <input
                                  className="input-text"
                                  id="departure_time"
                                  name="departure_time"
                                  value={this.state.departure_time}
                                  onChange={this.handleChange}
                                  type="time"
                                  autoComplete="off"
                                  required
                                />
                              </div>
                            </label>
                          </div>
                          <div className="small-12 medium-6 cell">
                            <label className="DepartFlightEnter">
                              {" "}
                              <span> Flight Number:</span>
                              <div className="input-group">
                                <span className="bg-icons input-group-time">
                                  <FontAwesomeIcon icon="plane-departure" />{" "}
                                </span>
                                <input
                                  className="input-text"
                                  id="departure_flight"
                                  value={this.state.departure_flight}
                                  name="departure_flight"
                                  onChange={this.handleChange}
                                  type="text"
                                  placeholder="Type Flight Number"
                                  autoComplete="off"
                                  required
                                />
                              </div>
                            </label>
                          </div>
                          <div className="small-12 medium-6 cell">
                            <label className="DepartAirlineEnter">
                              {" "}
                              <span>Airline's Name:</span>
                              <div className="input-group">
                                <span className="bg-icons input-group-time">
                                  <FontAwesomeIcon icon="plane-departure" />
                                </span>
                                <Select
                                  className="select-search"
                                  onChange={this.handleChangeAirlineDeparture}
                                  options={this.state.airlines}
                                />
                              </div>
                            </label>
                          </div>
                          <div className="small-12 medium-6 cell">
                            <label className="DepartHotelExtra">
                              {" "}
                              <span> Hotel</span>
                              <div className="input-group">
                                <span className="bg-icons input-group-time">
                                  <FontAwesomeIcon icon="map-marker-alt" />
                                </span>
                                <input
                                  className="input-text"
                                  id="hotel_extra"
                                  name="hotel_extra"
                                  value={this.state.hotel_extra}
                                  onChange={this.handleChange}
                                  type="text"
                                  placeholder="Type Departure Hotel"
                                  autoComplete="off"
                                />
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <hr />
                  <div className="grid-x grid-padding-x ">
                    <div className="medium-12 cell">
                      <h4>
                        <FontAwesomeIcon icon="pen-alt" />
                        Observations:
                      </h4>
                      <p>Do you have any observations or special requests?</p>
                      <textarea
                        id="observations"
                        value={this.state.observations}
                        name="observations"
                        onChange={this.handleChange}
                        type="text"
                        placeholder="type observations"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                  <div className="small-12 align-self-bottom cell">
                    <button
                      id="ibtnConfirm"
                      type="submit"
                      className="submit button warning expanded"
                    >
                      <b className="big">CONFIRM RESERVATION</b>
                    </button>
                  </div>
                </form>
              </div>
              <ReservationSummary reserve={this.state}></ReservationSummary>
            </div>
          </div>
          <Modal
            isOpen={this.state.modalConfirmControl}
            toggle={this.closeModal}
            size="lg"
          >
            <ModalBody>
              <ModalContent
                reserve={this.state}
                closeModal={this.closeModal}
                saveReservation={this.saveReservation}
              ></ModalContent>
            </ModalBody>
          </Modal>
          <Footer></Footer>
        </div>
      </Loading>
    );
  }
}
